import { computed, unref, type MaybeRef } from 'vue'
import { useGetCar } from '@autobid/ui/composables/useCar'

export const useCarGoft = (carId: MaybeRef<number>) => {
  const { locale } = useI18n()
  const { getCar } = useGetCar()

  return computed(() => {
    const car = getCar(unref(carId))

    if (!car?.goft) return null

    const goftForLocale = car.goft[locale.value.toUpperCase()]

    if (
      !goftForLocale ||
      !goftForLocale?.text?.length ||
      !goftForLocale?.headline?.length
    ) {
      return null
    }

    return goftForLocale
  })
}
