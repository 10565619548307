<template>
  <CommonLink :href="detailsUrl" class="relative block">
    <CommonPicture
      v-if="imageToDisplay"
      v-bind="$attrs"
      :images="imageToDisplay.links"
      :sizes="THUMBNAIL_SIZES"
      default-size="l"
      class="h-auto max-h-[300px] w-full object-cover object-top"
      :alt="imageToDisplay.description.categoryLabel"
    />
    <ElementsCarThumbnailPlaceholder v-else />
    <FinishedImageOverlay :car="car" :state="state" size="big" />
  </CommonLink>
</template>

<script setup lang="ts">
import FinishedImageOverlay from '@autobid/ui/components/elements/car/FinishedImageOverlay.vue'
import { useCurrentAuctionState } from '@autobid/ui/composables/car/useCurrentAuctionState'
import { useCarsStore } from '@autobid/nuxt-pinia-store/store/useCarsStore'
import { storeToRefs } from 'pinia'
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'

type Props = {
  carId: number
}

const props = defineProps<Props>()
const { carsData } = storeToRefs(useCarsStore())
const car = computed(() => carsData.value?.items[props.carId])
const imageToDisplay = computed(
  () => Object.values(car.value?.imageGroups?.itemMainImageSubgroup ?? {})[0]
)
const state = useCurrentAuctionState(car)
const { getLink } = useAuctionPagesLink()

const detailsUrl = computed(() =>
  getLink({
    page: 'details',
    slug: car.value.slug
  })
)

const THUMBNAIL_SIZES = {
  l: 1279
}
</script>
