import { useCarsStore } from '@autobid/nuxt-pinia-store/store/useCarsStore'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import type { AuctionCar } from '@autobid/ui/types/Car'
import { BAROMETER_QUERY_BID_AMOUNT } from '@autobid/ui/composables/useCar'
import { storeToRefs } from 'pinia'

type Resp = {
  data?: {
    singleItem: {
      item: {
        itemData: Pick<AuctionCar, 'bids'>
      }
    }
  }
  errors?: { message: string }[]
}

export const useAllBids = () => {
  const { carsData } = storeToRefs(useCarsStore())
  const { $customFetch } = useCustomFetch()
  const push = usePush()
  const { t } = useI18n()
  const prefetched = ref(false)

  const query = `
    query allBids($id: Int!) {
      singleItem(
        params: {
          id: $id, 
          bidParams: {limit: null}
        }
      ) {
        item {
          itemData {
              bids
          }
        }
      }
    }
  `
  const prefetch = async (carId: number) => {
    const currentAmountOfBids = carsData.value.items[carId].bids.length ?? 0

    if (currentAmountOfBids !== BAROMETER_QUERY_BID_AMOUNT || prefetched.value)
      return

    const result = await $customFetch<Resp>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'auctions',
        queryMethod: 'POST',
        queryUrl: '/api/v1/query',
        query,
        variables: {
          id: carId
        }
      }
    })

    if (result.errors?.length) {
      push.error(t('unexpected-error'))
      return
    }

    const bids = result.data.singleItem.item.itemData.bids

    if (bids?.length) {
      carsData.value.items[carId].bids = bids

      prefetched.value = true
    }
  }

  return { prefetch }
}
