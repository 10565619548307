<template>
  <div class="mt-4 hidden flex-col gap-4 lg:flex">
    <div class="grid w-full grid-cols-3 items-center justify-between">
      <ElementsCarTopPagination
        :id="car.id"
        :catalog-number="car.catalogNumber"
        type="barometer"
      />
      <div class="col-span-2 flex w-full items-center justify-end gap-2">
        <AdditionInfo :car-id="car.id" />
        <ElementsCarStaticActions
          :sca-popup-slug="scaPopupSlug"
          :car="car"
          size="big"
        />
      </div>
    </div>
    <div
      class="container mx-auto grid w-full grid-cols-3 items-stretch justify-between gap-2"
    >
      <LeftPanel :car="car" />
      <BidList currency="EUR" :car-id="car.id" />
      <div class="flex flex-col gap-2">
        <TimeInfo
          :car="car"
          :is-countdown-over="isCountdownOver"
          :time-remaining="timeRemaining"
        />
        <BidAction :car="car" />
        <BidInfo
          :car="car"
          :tax-popup-slug="taxPopupSlug"
          :state-text="title"
        />
      </div>
    </div>
  </div>
  <BarometerButtonsDesktop
    :current-url="currentUrl"
    :details-url="detailsUrl"
    :car="car"
    :transport-url="transportUrl"
    class="mt-4 hidden flex-col gap-2 lg:flex"
    @show-all-bids="$emit('showAllBids')"
  />
  <div class="my-8 hidden lg:block">
    <ElementsCarInputPagination
      :id="car.id"
      :catalog-number="car.catalogNumber"
      :auction-id="car.auctionId"
      type="barometer"
    />
  </div>
</template>

<script setup lang="ts">
import type { AuctionCar } from '@autobid/ui/types/Car'
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'
import type { AuctionItemProps } from '@autobid/ui/types/components/AuctionItem'
import { useAuction } from '@autobid/ui/composables/useAuction'
import AdditionInfo from '../../elements/car/AdditionInfo.vue'
import BarometerButtonsDesktop from './BarometerButtonsDesktop.vue'
import TimeInfo from './TimeInfo.vue'
import BidAction from './BidAction.vue'
import BidInfo from './BidInfo.vue'
import LeftPanel from './LeftPanel.vue'
import BidList from './BidList.vue'

type Props = {
  car: AuctionCar
  timeRemaining: string
  isCountdownOver: boolean
  title: string
}

const props = defineProps<Props>()

defineEmits(['showAllBids'])

const { auction } = useAuction(props.car.auctionId)
const { getLink } = useAuctionPagesLink(props.car.appId)
const detailsUrl = computed(() =>
  getLink({
    page: 'details',
    slug: props.car.slug
  })
)
const currentUrl = computed(() =>
  getLink({
    page: 'current',
    slug: auction.value.slug
  })
)

const { transportUrl, scaPopupSlug, taxPopupSlug }: AuctionItemProps =
  inject('auction-item-props')
</script>
