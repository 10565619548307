<template>
  <div
    class="flex w-full flex-col justify-between gap-2 px-4 py-3 text-[#747679] md:text-primary lg:border lg:border-primary"
    :class="{
      'py-8': car.stage === 'FINISHED'
    }"
  >
    <div class="mb-auto mt-auto h-1/4">
      <div class="flex items-start justify-between md:-mt-4">
        <p
          v-if="car.stage !== 'FINISHED'"
          class="self-end pr-1 font-medium text-primary"
        >
          <span v-if="car.inActiveHotbidPhase">
            {{ $t('shared-car.hotbid') }}
          </span>
          <span v-else-if="!isCountdownOver || car.id === carInAuction?.id">
            {{ $t('barometer.time-to-hotbid') }}
          </span>
        </p>
        <CommonIconClock
          class="relative top-4 ml-auto hidden h-11 w-11 md:block"
          :class="{
            'animation-shake': car.inActiveHotbidPhase
          }"
        />
      </div>
    </div>
    <div class="py-2">
      <p class="w-full text-xl font-bold md:text-center md:text-3xl">
        <span v-if="car.stage === 'FINISHED'">
          {{ $t('barometer.auction-finished') }}
        </span>
        <span v-else-if="isNextCarInfoRendered && isCountdownOver">
          {{
            $t(
              `barometer.lots-left-${
                catalogNumberDiff > 1 ? 'plural' : 'singular'
              }`,
              { amount: catalogNumberDiff }
            )
          }}
        </span>
        <span
          v-else
          class="block"
          :class="{
            'text-5xl': car.inActiveHotbidPhase,
            'md:min-h-[77px]': !car.inActiveHotbidPhase
          }"
        >
          {{ timeRemaining }}
        </span>
      </p>
    </div>

    <p
      v-if="car.id === carInAuction?.id && car.inActiveHotbidPhase"
      class="hidden text-center text-lg uppercase text-[#747679] md:block"
    >
      {{ $t('barometer.last-opportunity-to-place-bids') }}
    </p>

    <NextCarInfo
      v-else-if="isNextCarInfoRendered"
      :auction-id="car.auctionId"
      :hide-countdown="!isCountdownOver"
    />
  </div>
</template>

<script setup lang="ts">
import type { AuctionCar } from '@autobid/ui/types/Car'
import { useAuction } from '@autobid/ui/composables/useAuction'
import { computed, inject } from 'vue'
import NextCarInfo from '@autobid/ui/components/sections/barometer/NextCarInfo.vue'
import { useCar } from '@autobid/ui/composables/useCar'

type Props = {
  car: AuctionCar
  timeRemaining: string
  isCountdownOver: boolean
}
const props = defineProps<Props>()

const barometerType = inject('barometerType')
const { auction } = useAuction(props.car.auctionId)
const { data: carInAuction } = useCar({
  type: 'current',
  auctionId: props.car.auctionId
})
const isNextCarInfoRendered = computed(
  () =>
    barometerType === 'item' &&
    auction.value?.stage !== 'FINISHED' &&
    props.car.id !== carInAuction.value?.id
)
const catalogNumberDiff = computed(() =>
  carInAuction.value &&
  isNextCarInfoRendered.value &&
  props.car.stage !== 'FINISHED'
    ? props.car.catalogNumber - carInAuction.value.catalogNumber
    : 0
)
</script>
