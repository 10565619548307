<template>
  <div
    v-if="carInAuction"
    class="mt-2 hidden items-center gap-3 border-t border-[#707070] pt-2 md:flex"
  >
    <div class="w-full">
      <CurrentAuctionButton :slug="auction.slug" class="!inline-block" />

      <div
        v-if="!hideCountdown"
        class="flex items-center justify-between gap-2"
      >
        <p class="font-semibold">
          <span v-if="carInAuction.inActiveHotbidPhase" class="text-lg">
            {{
              $t('barometer.hotbid-nr', {
                catalogNumber: carInAuction.catalogNumber
              })
            }}
          </span>
          <span v-else>
            {{ $t('barometer.time-to-hotbid') }}
          </span>
        </p>
        <p class="text-lg font-bold">
          {{ timeRemaining.full }}
        </p>
      </div>
    </div>

    <div
      class="flex aspect-[4/3] w-2 min-w-[116px] items-center justify-center"
      :class="{ border: !mainImage }"
    >
      <img
        v-if="mainImage"
        class="max-w-full"
        :src="mainImage.links.m"
        :alt="carInAuction.name"
      />
      <div v-else class="w-1/2">
        <ElementsCarThumbnailPlaceholder class="!border-0" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAuction } from '@autobid/ui/composables/useAuction'
import CurrentAuctionButton from '@autobid/ui/components/elements/car/CurrentAuctionButton.vue'
import { useCarCountdown } from '@autobid/ui/composables/car/countdown/useCarCountdown'
import { useCar } from '@autobid/ui/composables/useCar'

interface Props {
  auctionId: number
  hideCountdown?: boolean
}

const props = defineProps<Props>()

const { auction } = useAuction(props.auctionId)
const { data: carInAuction } = useCar({
  type: 'current',
  auctionId: props.auctionId
})
const mainImage = computed(
  () =>
    Object.values(
      carInAuction.value?.imageGroups.itemMainImageSubgroup ?? {}
    )[0]
)
const { timeRemaining } = useCarCountdown(carInAuction)
</script>
