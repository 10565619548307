<template>
  <div v-if="shouldBeRendered">
    <elements-button
      class="!inline-block"
      type="button"
      @click="confirmModel = true"
    >
      {{ $t('shared-car.free-reservation-button-text') }}
    </elements-button>

    <FreeReservationConfirm
      :active="confirmModel"
      :car-id="car.id"
      @close="confirmModel = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'
import { ref, computed } from 'vue'
import { useCheckUserPermission } from '@autobid/ui/composables/useCheckUserPermission'
import type { AuctionCar } from '@autobid/ui/types/Car'
import { useProfile } from '@autobid/ui/composables/useProfile'
import FreeReservationConfirm from './FreeReservationConfirm.vue'

type Props = {
  car: AuctionCar
}

const props = defineProps<Props>()
const { tokenData } = useToken()
const { checkUserPermission } = useCheckUserPermission()
const { data: profile } = useProfile()
const confirmModel = ref(false)

const shouldBeRendered = computed(() => {
  if (
    !tokenData.value ||
    props.car.stage === 'FINISHED' ||
    props.car.state !== 'RESERVED'
  ) {
    return false
  }

  if (checkUserPermission('edit_reserve_status')) return true

  if (
    checkUserPermission('edit_reserve_status_own_car') &&
    props.car.supplier.id === tokenData.value.id
  ) {
    return true
  }

  const subDeliverers = new Set(
    profile.value?.extendedData?.subDeliverers || []
  )

  return subDeliverers.has(props.car.supplier.id.toString())
})
</script>
