<template>
  <CommonDialog :opened="active" @close="emits('close')">
    <template #title> {{ $t('all-bids-dialog.title') }} </template>
    <template #content>
      <div ref="contentNode" class="w-full">
        <div v-if="isAuthed" class="w-full">
          <div
            class="general-info mb-2 flex flex-col justify-between border-b border-primary pb-2 font-semibold text-primary sm:flex-row"
          >
            <p>{{ $t('cars-list.auction-no') }}. {{ car.auctionNumber }}</p>
            <p>{{ $t('cars-list.catalog-no') }}. {{ car.catalogNumber }}</p>
            <p>
              {{ $t('all-bids-dialog.status') }}:
              {{ $t(`shared-car.stage.${car.stage}`) }}
            </p>
          </div>

          <div v-if="loadingNewCars">
            <div class="mb-2 h-7 w-full animate-pulse bg-gray-200"></div>
            <div
              v-for="i in 11"
              :key="i"
              class="mb-0.5 h-6 w-full animate-pulse bg-gray-200"
            ></div>
          </div>

          <div v-else class="flex gap-2">
            <table class="table w-full text-xs">
              <thead>
                <tr>
                  <th v-if="atLeastOneBidHasNickname">
                    {{ $t('all-bids-dialog.bidder') }}
                  </th>
                  <th>{{ $t('all-bids-dialog.offer') }}</th>
                  <th>{{ $t('all-bids-dialog.date') }}</th>
                  <th>{{ $t('all-bids-dialog.hour') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="bid in bids" :key="bid.amount">
                  <td v-if="atLeastOneBidHasNickname">
                    {{ bid.user_nickname }}
                  </td>
                  <td>
                    {{ formatPrice(bid.amount) }}
                  </td>
                  <td>
                    {{ formatDate(bid.timestamp) }}
                  </td>
                  <td>
                    {{ formatTime(bid.timestamp) }}
                  </td>
                </tr>
                <tr v-if="!bids?.length">
                  <td colspan="4">{{ $t('all-bids-dialog.no-bids') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p v-else>{{ $t('all-bids-dialog.not-authorized-user-info') }}</p>
      </div>
    </template>
    <template #buttons>
      <div class="flex flex-wrap items-center justify-between gap-2">
        <ElementsButton
          v-if="!loadingNewCars && isAuthed && bids?.length"
          size="small"
          :outline="true"
          color="primary"
          @click="handlePrint"
        >
          {{ $t('all-bids-dialog.print') }}
        </ElementsButton>

        <ElementsButton
          size="small"
          color="primary"
          :class="{
            'ml-auto': !isAuthed || loadingNewCars || !bids?.length
          }"
          @click="emits('close')"
        >
          {{ $t('close') }}
        </ElementsButton>
      </div>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
import { useCarsStore } from '@autobid/nuxt-pinia-store/store/useCarsStore'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useAllBids } from '@autobid/ui/composables/car/useAllBids'
import { formatPrice } from '@autobid/ui/utils/formatPrice'
import { formatDate } from '@autobid/ui/utils/date/formatDate'
import { formatTime } from '@autobid/ui/utils/date/formatTime'
import { getUsersBids } from '@autobid/ui/utils/car/getUsersBids'

type Props = {
  carId: number
  active: boolean
}

const props = defineProps<Props>()
const contentNode = ref<HTMLDivElement>()
const emits = defineEmits(['close'])
const { isAuthed } = useAutobidAuth()
const { prefetch } = useAllBids()
const { loadingNewCars, carsData } = storeToRefs(useCarsStore())
const { t } = useI18n()

const car = computed(() => carsData.value?.items[props.carId])

const bids = computed(() => getUsersBids(car.value.bids ?? []))

const atLeastOneBidHasNickname = computed(
  () => car.value.bids.findIndex((bid) => bid.user_nickname?.length) >= 0
)

const initBids = () => {
  if (props.active && isAuthed.value) {
    prefetch(props.carId)
  }
}
initBids()

const handlePrint = () => {
  const divContents = contentNode.value?.innerHTML

  const styles = `
    @page {
      margin: 25px;
    }

    .general-info {
      display: flex;
      justify-content: space-between;
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    td, th {
      border: 1px solid #747679;
      padding: 5px 7px;
    }

    h1 {
      font-size: 20px;
      text-align: center;
    }
  `
  const a = window.open('', '', 'height=600, width=1000')
  const title = `${car.value.name} - ${t('all-bids-dialog.title')}`
  a.document.write(`
    <html>
      <head>
        <title>${title}</title>
        <style>${styles}</style>
      </head>
      <body>
        <h1>${title}</h1>
        ${divContents}
      </body>
    </html>
  `)
  a.document.close()
  a.focus()
  a.print()
}

watch([props], () => {
  initBids()
})
</script>

<style lang="scss" scoped>
th,
td {
  padding: 5px 7px;
}

tbody tr:nth-child(odd) {
  background: #f2f2f2;
}
</style>
