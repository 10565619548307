<template>
  <div class="flex w-full flex-col">
    <div class="relative h-80 w-full pr-8">
      <div class="absolute left-2 top-2 z-10">
        <ElementsCarHighestBidderTooltip :car="car" />
      </div>

      <Image
        class="absolute bottom-12 left-0 right-0 top-0 h-[96%] w-full object-cover"
        :car-id="car.id"
        :target="shouldOpenDetailsInNewPage ? '_blank' : undefined"
      />
      <div class="absolute right-[-5.25rem] top-1/4 mt-2 -rotate-90 text-xl">
        Auktionsbarometer ©
      </div>
    </div>
    <div class="z-10 flex w-full flex-wrap gap-2 px-2">
      <ElementsCarDynamicActions :car="car" size="big" />
      <ElementsCarSupplierLogo :car="car" class="w-48" />
    </div>
    <div class="mt-4 flex w-full flex-1 flex-col border border-primary">
      <div class="flex flex-col">
        <div
          class="overflow-hidden bg-[rgb(248,248,248)] px-3 py-2 text-lg font-semibold"
          :title="car.name"
        >
          <span class="line-clamp-2"> {{ car.name }}</span>
        </div>
        <div class="p-2 text-sm text-gray-500">
          {{ getDescription(car) }}

          <ElementsCarParameters class="mt-2" :car="car" />
        </div>
      </div>
      <div class="flex flex-col">
        <div class="bg-[#EAE7E0] px-3 py-2 text-lg font-semibold uppercase">
          {{ $t('equipment') }}
        </div>
        <div class="p-2 text-sm text-gray-500">
          <p>{{ Object.values(car?.ranking ?? {}).join(', ') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AuctionCar } from '@autobid/ui/types/Car'
import { useCarDescription } from '@autobid/ui/composables/car/useCarDescription'
import { useCheckOpenDetailsInNewPage } from '@autobid/ui/composables/car/useCheckOpenDetailsInNewPage'
import Image from './Image.vue'

type Props = {
  car: AuctionCar
}

defineProps<Props>()

const shouldOpenDetailsInNewPage = useCheckOpenDetailsInNewPage()
const { getDescription } = useCarDescription()
</script>
