<template>
  <div class="grid grid-cols-3 gap-2">
    <template v-if="car.price.current">
      <ElementsButton
        v-for="option in car.bidOptions"
        :key="option"
        type="block"
        background-color="var(--color-primary-dark)"
        :disabled="!isUserPermitted"
        @click="
          emits('handleBid', {
            currentId: car.id,
            currentPrice: car.price.current ?? 100,
            currentType: 1,
            currentValue: option
          })
        "
        >+{{ option }}</ElementsButton
      >
    </template>
    <span v-else class="col-span-3">{{
      $t('barometer.bid-not-started-info', {
        price: formatPrice(car.price.minimal)
      })
    }}</span>
  </div>
</template>

<script setup lang="ts">
import { useIsPermittedToBid } from '@autobid/ui/composables/car/useIsPermittedToBid'
import { formatPrice } from '@autobid/ui/utils/formatPrice'
import type { AuctionCar } from '@autobid/ui/types/components/CarsList'
import type { Bid } from '@autobid/ui/composables/car/useBidAction'

type Props = {
  car: AuctionCar
  skipConfirmation?: boolean
}

defineProps<Props>()
const isUserPermitted = useIsPermittedToBid()

type Emits = {
  (e: 'handleBid', params: Bid): Promise<void>
}

const emits = defineEmits<Emits>()
</script>
