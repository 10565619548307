<template>
  <CommonDialog
    :main-props="{ id: 'car-offer-sheet-content' }"
    :opened="isOpen"
    :footer-props="{ className: 'shadow-inner p-4' }"
    @close="handleClose"
  >
    <template #title>
      {{ $t('car-offer-sheet-dialog.title') }}
    </template>
    <template #content>
      <div class="w-full">
        <TabGroup>
          <TabList class="grid grid-cols-2 space-x-1 rounded-xl p-1">
            <Tab
              v-for="category in Object.keys(schema)"
              :key="category"
              v-slot="{ selected }"
              as="template"
            >
              <button
                :class="[
                  'flex w-full items-center py-2.5 text-center text-sm font-medium leading-5 md:h-full',
                  'h-full border transition-colors focus:outline-none group-first:rounded-l group-last:rounded-r',
                  selected
                    ? 'border-[#364A5D] bg-primaryDark text-white shadow'
                    : 'border-[#C1C1C1] bg-white text-primary hover:bg-[#E5E8F0] hover:text-[#364A5D]'
                ]"
              >
                <Icon
                  size="24"
                  class="mx-2"
                  :name="category === 'user' ? 'ph:user' : 'mdi:car-outline'"
                />
                {{ $t(`car-offer-form-dialog.label-${category}-info`) }}
              </button>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel
              v-for="(currentSchema, idx) in Object.values(schema)"
              :key="idx"
              class="rounded-xl bg-white p-3"
            >
              <FormKit
                v-model="model"
                type="form"
                :actions="false"
                @submit="handleSubmit"
                @change="onChange"
              >
                <FormKitSchema :schema="currentSchema" :floating-label="true">
                  <div
                    v-if="previewImage || sheet?.data.carlabel.logoPath"
                    class="flex flex-col gap-2"
                  >
                    <p class="text-lg text-primary">
                      {{ $t('car-offer-form-dialog.preview-logo') }}
                    </p>
                    <img
                      :src="previewImage ?? sheet?.data.carlabel.logoPath"
                      alt="logo"
                      class="h-32 w-auto object-cover"
                    />
                  </div>
                </FormKitSchema>
              </FormKit>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </template>
    <template #buttons>
      <footer class="flex w-full items-center gap-2">
        <button
          v-if="isChanged"
          class="flex justify-self-start"
          @click="scrollToSave"
        >
          <Icon
            name="material-symbols:info-outline"
            class="text-primary"
            size="24"
          />
          <div>
            <p class="ml-1 text-sm">
              {{ $t('car-offer-form-dialog.save-info') }}
            </p>
            <p class="text-xs">
              ({{ $t('car-offer-form-dialog.click-to-scroll-to-save') }})
            </p>
          </div>
        </button>
        <div class="ml-auto flex items-center gap-2">
          <ElementsButton
            :disabled="isChanged"
            class="disabled:opacity-50"
            @click="print"
          >
            {{ $t('car-offer-sheet-dialog.create-pdf') }}
          </ElementsButton>
        </div>
      </footer>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
import { useCarOfferSheet } from '@autobid/ui/composables/car/useCarOfferSheet'
import { ref, computed } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

type Props = {
  carId: number
  isOpen: boolean
}

const scrollToSave = () => {
  const item = document.getElementById('car-offer-sheet-content')
  if (!item) return
  item.scrollTo({
    top: item.scrollHeight,
    left: 0,
    behavior: 'smooth'
  })
}

const props = defineProps<Props>()

const emits = defineEmits(['close', 'confirm'])

const isFormModalShown = ref(false)

function handleClose() {
  if (isFormModalShown.value) {
    return
  }
  emits('close')
}

const {
  onChange,
  handleSubmit,
  print,
  isChanged,
  schema,
  previewImage,
  model,
  sheet
} = useCarOfferSheet(
  props.carId,
  computed(() => props.isOpen)
)
</script>
